import Fileupload from "../modules/file-upload";
// Import images display on advanced form
import faChecked from "../../public/img/faChecked.png";
import faCross from "../../public/img/faCross.png";
import radioChecked from "../../public/img/radio_button_checked.png";
import radioUnchecked from "../../public/img/radio_button_unchecked.png";

AdvancedFormController.$inject = ['$element', 'allevaApi', /*'darkDeploy',*/ '$scope', '$rootScope', 'allevaAutosave', 'dayjs', 'noty', '$interval'];
export default function AdvancedFormController($element, allevaApi, /*darkDeploy,*/ $scope, $rootScope, allevaAutosave, dayjs, noty, $interval) {


        /***************************
         * Properties
         **************************/
        const ctrl = this;
        
        // Flags
        ctrl.saved        = false;
        ctrl.fromAutosave = false;
        ctrl.initialized  = false;
        ctrl.isFormDataValid = true;


        // Data
        ctrl.registeredEvents = [];
        ctrl.formDataFacets   = [];
        ctrl.formDataValues   = [];
        ctrl.$element         = $element;
        ctrl.countDownTime    = 0;
        ctrl.autosaveTimer    = null;
        ctrl._autoSaveTime    = 6;
        ctrl.selectedSignatureRequiredComponents = [];
        ctrl.requiredComponents = [];

        // Definitions
        const DataKind = { Integer: "Integer", Boolean: "Boolean", Text: "Text", 
            Decimal: "Decimal", Image: "Image", File: "File", DateTime: "DateTime", Signature: "Signature", Json: "Json" };

            
        /***************************
         * Init
         **************************/
        async function initializeData() {

            /*****************************************************************
             *  DEVELOPMENT ONLY ENVIRONMENT */
            // We don't want AdvancedForm being initiated if FormId is not defined.


            // if(darkDeploy.staging){
            //     if(typeof ctrl.advancedFormId === 'undefined'){
            //         // TODO
            //         // toastr.warning("Make sure a FormId is being passed to AdancedForm.", "WARNING:");
            //     }
            // }


            /*****************************************************************/
            if (ctrl.advancedFormId) {
                const dataAndFacets = await getAdvancedFormData(ctrl.advancedFormId);

                // TODO upgrade webpack
                ctrl.formDataValues     = dataAndFacets ? dataAndFacets.dataValues || [] : [];
                ctrl.formDataFacets     = dataAndFacets ? dataAndFacets.dataFacets || [] : [];

                ctrl.callEventHandler();
                ctrl.initialized = true;
            }
        }

        ctrl.$onInit = function () {

        };

        ctrl.$postLink = async function () {
            await initializeData();
            ctrl.initializeScopes();
            const isValidated = validateFormData();
            if (!isValidated) {
                ctrl.isFormDataValid = false;
            }
            ctrl.setComponentValues();

            if (ctrl.formObject)
                ctrl.formObject.IsCompleted = isCompleted();

            if (!ctrl.isEditable()) {
                ctrl.setReadOnlyValues();
            }
        }

        ctrl.$doCheck = function () {
            
        }

        /***************************
         * Actions
         **************************/
        ctrl.refresh = async () => {
            await initializeData();
        }

        ctrl.isEditable = () => {
            return ctrl.formMode != 'readonly';
        }

        /***************************
         * Event Handlers
         **************************/
        ctrl.registerEventHandler = (event) => {
            ctrl.registeredEvents.push(event);

            // TODO: Create different event handlers
            // For initialized data
            if(ctrl.initialized){
                ctrl.callEventHandler();
            }
        }

        ctrl.callEventHandler = () => {
            ctrl.registeredEvents.forEach(handler => {
                handler();
            });
        }

        $scope.$on("AdvancedForm.requestSave", (e, markCompleted) => { 
            $interval.cancel(ctrl.autosaveTimer);
            ctrl.submit(markCompleted);
        });

        $scope.$on("FormDesigner.didSave", () => { 
            // handler code here 
            ctrl.refresh();
        });

        $scope.$on("AdvancedForm.stopAutosaveTimer", () => {
            $interval.cancel(ctrl.autosaveTimer);
        });

        const eventListenerTriggers = () => {
            ctrl.isDirty = true; // Make input dirty.
            startAutosaveTimer();
            allevaAutosave.trigger(); // map the autosave trigger to this event listener.
        }

        /***************************
         * GET
         **************************/
        ctrl.getComponentValueFromData = function (uuid) {

            const facet = ctrl.formDataFacets.filter(dataFacet => {
                return dataFacet.uuid == uuid;
            });

            if (!facet)
                return null;

            var data = ctrl.formDataValues.filter(dataValue => {
                return dataValue.dataFacetId == facet.id;
            });

            if (!data)
                return null;

            return getValueForKind(data, facet.dataKind);
        };

        ctrl.getComponentValueFromForm = function (uuid) {
            let inputs = Array.from(getInputs(uuid));
            if (inputs.some(element => element.tagName === 'ADVANCEDFORMMULTISELECT')) {
                inputs = inputs.filter(element => element.tagName === 'ADVANCEDFORMMULTISELECT');
            }
            let   result = "";

            // TODO: stop looping through inputs for every component value
            inputs.forEach(input => {
                
                let type = getType(input);
                switch (type) {
                    case "checkbox": {
                        if (input.checked) {
                            if (result) {
                                result += `,${input.value}`;
                            }
                            else {
                                result += input.value;
                            }
                        }
                        break;
                    }
                    case "radio":
                        if (input.checked) {
                            result = input.value;
                        }
                        break;
                    case "signature":
                        // TODO: get via angular component
                        result = angular.element(input).jSignature('getData');
                        break;
                    case "time":
                        if(input.value)
                            result = dayjs(input.value, 'HH.mm').utc().format('HH:mm');
                        break;
                    case "timepicker":
                        result = input.value === '' ? null : dayjs(input.value, 'h:mm A').utc().format('h:mm A');
                        break;
                    case "file":
                        // find the file controller
                        let fileCtrl = angular.element(input).controller('fileupload');
                        if (fileCtrl){
                            result = fileCtrl.getValue();
                        }
                        break;
                    case 'medications':
                        const medicationsCtrl = angular.element(input).controller('clientmedications');
                        if (medicationsCtrl) {
                            result = medicationsCtrl.getJsonData();
                        }
                        break;
                    case 'multiselect':
                        const multiselectCtrl = angular.element(input).controller('advancedformmultiselect');
                        if (multiselectCtrl){
                            result = multiselectCtrl.getStringData();
                        }
                        break;
                    case 'button':
                        break;
                    default: 
                        result = input.value;
                        break;
                }
            });

            return result == "null" ? null : result;
        };

        function getType(input) {
            if (input.name == "timeInput")
                return "timepicker";

            if (input.name == "calculation")
                return "calculation";

            if (input.type)
                return input.type;

            if (input.tagName == "SIGNATURE")
                return "signature";

            if (input.tagName == "ADVANCEDFORMMULTISELECT")
                return "multiselect";

            if (input.tagName == "SELECT")
                return "dropdown";

            if (input.tagName == "FILEUPLOAD"){
                return "file";
            }

            if (input.tagName == "CLIENTMEDICATIONS"){
                return "medications";
            }
            // TODO: handle error condition
        }

        ctrl.getFormComponents = function () {
            return ctrl.formDataFacets;
        }

        function getInputs(uuid) {
            let selector = `[data-guid="${uuid.toLowerCase()}"] input, [data-guid="${uuid.toLowerCase()}"] textarea, [data-guid="${uuid.toLowerCase()}"] select, [data-guid="${uuid.toLowerCase()}"] signature, [data-guid="${uuid.toLowerCase()}"] fileupload, [data-guid="${uuid.toLowerCase()}"] button, [data-guid="${uuid.toLowerCase()}"] advancedformmultiselect, [data-guid="${uuid.toLowerCase()}"] clientmedications`;
            return ctrl.$element[0].querySelectorAll(selector);
        }

        function getReadOnlyElements(uuid) {
            return ctrl.$element[0].querySelectorAll(`[data-guid="${uuid.toLowerCase()}"] #readOnlyValue`);
        }

        ctrl.setComponentValues = function () {
            return new Promise((resolve, reject) => {
                const dataFacetMap = new Map(ctrl.formDataFacets.map(facet => [facet.id, facet]));
                // Adding event listeners for autosave
                ctrl.formDataFacets.forEach(dataFacet => {
                    const facet = dataFacetMap.get(dataFacet.id);
                    if (facet) {
                        const inputs = getInputs(facet.uuid);
                        inputs.forEach(input => {
                            input.addEventListener("input", eventListenerTriggers); 
                        });
                    }
                })
                ctrl.formDataValues.forEach(dataValue => {
                    const facet = dataFacetMap.get(dataValue.dataFacetId);

                    if (facet) {
                        let inputs = Array.from(getInputs(facet.uuid));
                        if (facet.dataKind == DataKind.File){
                            inputs = inputs.splice(1);
                        }
                        if (inputs.some(element => element.tagName === 'ADVANCEDFORMMULTISELECT')) {
                            inputs = inputs.filter(element => element.tagName === 'ADVANCEDFORMMULTISELECT');
                        }
                        const readOnlyElements = getReadOnlyElements(facet.uuid);
                        const value = getValueForKind(dataValue, facet.dataKind);
                        const createdDate = dataValue.createdDate;
                        const defaultReadOnlyElement = readOnlyElements[0];

                        inputs.forEach(input => {

                            let type = getType(input);

                            switch (type) {
                                case "checkbox":
                                    if (value) {
                                        const splitValues = value.split(",");
                                        input.checked = splitValues.includes(input.value);
                                    }
                                    break;
                                case "radio":
                                    input.checked = (input.value == value);
                                    break;
                                case "time":
                                    if(value)
                                        input.value = dayjs.utc(value).local().format('HH:mm');
                                        if (defaultReadOnlyElement) {
                                            angular.element(defaultReadOnlyElement)[0].textContent = dayjs.utc(value).local().format('h:mm a');
                                        }
                                    break;
                                case "timepicker":
                                    const formattedTime = value ? dayjs.utc(value).local().format('h:mm A') : null;
                                    input.value = formattedTime;
                                    if (defaultReadOnlyElement) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = formattedTime;
                                    }
                                    break;
                                case "date":
                                    const formattedDate = value ? dayjs(value).utc().format("YYYY-MM-DD") : null;
                                    input.value = formattedDate;
                                    if (defaultReadOnlyElement) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = formattedDate;
                                    }
                                    break;
                                case "signature":
                                    // TODO: set via angular component
                                    setTimeout(() => {
                                        angular.element(input.querySelector('.signature-canvas-target')).jSignature('setData', value);
                                        angular.element(defaultReadOnlyElement)[0].src = value;
                                    }, 500)
                                    break;
                                case "file":
                                     // find the file controller
                                    let fileCtrl = angular.element(input).controller('fileupload');
                                    if (fileCtrl){
                                        fileCtrl.setValue(value).then(saveData => {
                                            if (saveData){
                                                angular.element(defaultReadOnlyElement)[0].innerText = saveData;
                                            }
                                        });
                                    }
                                    break;
                                case "select-one":
                                    input.value = value;
                                    input.forEach(option => {
                                        if (option.selected) {
                                            angular.element(defaultReadOnlyElement)[0].textContent = option.label;
                                        }
                                    });
                                    break;
                                case "multiselect":
                                    const multiselectCtrl = angular.element(input).controller('advancedformmultiselect');
                                    if (multiselectCtrl){
                                        multiselectCtrl.setData(value);
                                        if (defaultReadOnlyElement) {
                                            angular.element(defaultReadOnlyElement)[0].innerHTML = value.split(',').join('<br>');
                                        }
                                    }
                                    break;
                                case "textarea":
                                    input.value = value;
                                    if (input.classList.contains('auto-resize-textarea')) {
                                        input.style.height = 'auto';
                                        input.style.height = (input.scrollHeight) + 'px';
                                        input.style.overflowY = 'hidden';
                                      }    
                                    angular.element(defaultReadOnlyElement)[0].outerHTML = "<p id=readOnlyValue hidden=\"\">" + value.replace(/\n/g, "<br>") + "</p>";
                                    break;
                                case 'medications':
                                    const medicationsCtrl = angular.element(input).controller('clientmedications');
                                    if (medicationsCtrl) {
                                        if (value || ctrl.instanceId !== 0) {
                                            medicationsCtrl.leadId = ctrl.leadId;
                                            medicationsCtrl.medicationTime = dayjs.utc(createdDate).local().format('MM/D/YYYY h:mm a');
                                            const element = document.querySelector(`[data-facet-id="${facet.id}"]`);
                                            if (element) {
                                                const showDiscontinuedMeds = element.getAttribute('data-show-discontinued-meds');
                                                medicationsCtrl.showDiscontinued = showDiscontinuedMeds;
                                            }
                                            medicationsCtrl.setData(value);
                                            if (defaultReadOnlyElement) {
                                                angular.element(defaultReadOnlyElement)[0].innerHTML = medicationsCtrl.getPrintHtml();
                                            }
                                        }
                                    }
                                    break;
                                default:
                                    input.value = value;
                                    if (defaultReadOnlyElement) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = value;
                                    }
                                    break;
                            }
                        });                    
                    }
                });
            });
            
        }

        ctrl.initializeScopes = function () {
            // hacky
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);
                inputs.forEach(input => {
                    let type = getType(input);
                    switch (type) {
                        case "multiselect":
                            let multiselectCtrl = angular.element(input).controller('advancedformmultiselect');
                            if (multiselectCtrl) {
                                const element = document.querySelector(`[data-facet-id="${facet.id}"]`);
                                if (element) {
                                  const options = element.getAttribute('data-option-multiselect');
                                  multiselectCtrl.options = JSON.parse(options);
                                }
                            }
                            break;
                        case "file":
                            // find the file controller
                            let fileCtrl = angular.element(input).controller('fileupload');
                            if (fileCtrl){
                                fileCtrl.leadId = this.leadId;
                            }
                            break;
                        case "calculation":
                            let calculationCtrl = angular.element(input).controller('calculate');
                            if (calculationCtrl) {
                                calculationCtrl.facetId = facet.id;
                            }
                            break;
                        case 'medications':
                            if (ctrl.instanceId === 0) {
                                const medicationsCtrl = angular.element(input).controller('clientmedications');
                                if (medicationsCtrl) {
                                    medicationsCtrl.leadId = ctrl.leadId;
                                    medicationsCtrl.medicationTime = dayjs().format('MM/D/YYYY h:mm a');
                                    const element = document.querySelector(`[data-facet-id="${facet.id}"]`);
                                    if (element) {
                                        const showDiscontinuedMeds = element.getAttribute('data-show-discontinued-meds');
                                        medicationsCtrl.showDiscontinued = showDiscontinuedMeds;
                                    }
                                    medicationsCtrl.loadMedications().then(_ => {
                                        const readOnlyElements = getReadOnlyElements(facet.uuid);
                                        const defaultReadOnlyElement = readOnlyElements[0];
                                        if (defaultReadOnlyElement) {
                                            angular.element(defaultReadOnlyElement)[0].innerHTML = medicationsCtrl.getPrintHtml();
                                        }
                                    });
                                }
                            }
                            break;
                    }
                });
            });
        }

        ctrl.setReadOnlyValues = function () {
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);
                var readOnlyElements = getReadOnlyElements(facet.uuid);
                var defaultReadOnlyElement = readOnlyElements[0];
                var labelElements = ctrl.$element[0].querySelectorAll(`[data-guid="${facet.uuid.toLowerCase()}"] label`)
                labelElements.forEach(label => {
                    label.style.fontWeight = 'bold';
                });
                if (facet.dataKind == DataKind.Signature) {
                    inputs.forEach(input => {
                        setTimeout(() => {
                            if (input.type != "button") {
                                var signatureCanvas = angular.element(input.querySelector('.signature-canvas-target'));
                                signatureCanvas[0].hidden = true;
                                var clearButton = input.querySelector('#clearFormSignatureButton')
                                clearButton.parentNode.removeChild(clearButton);
                                defaultReadOnlyElement.hidden = false;
                            }
                        }, 500)
                    });
                }
                // TODO: Hacky, need to fix after mvp release
                else {
                    inputs.forEach(input => {
                        switch (input.type) {
                            case 'checkbox':
                                // prevent user from clicking checkbox
                                input.addEventListener('click', function (e) {
                                    e.preventDefault();
                                });
                                readOnlyElements[input.id].src = input.checked ? faChecked : faCross;
                                break;
                            case 'radio':
                                // prevent user from clicking radio button
                                input.addEventListener('click', function (e) {
                                    e.preventDefault();
                                });
                                readOnlyElements[input.id].src = input.checked ? radioChecked : radioUnchecked;
                                break;
                        }
                        input.hidden = true;
                        input.style.display = 'none';

                    });
                    if (defaultReadOnlyElement) {
                        readOnlyElements.forEach(element => {
                            element.hidden = false;   
                            element.style.visibility = '';
                        })

                        
                    }
                    
                }                          
            });
        }

        // TODO: This is a temporary fix for saving read only html.  Will need to change.
        const getReadOnlyHtml = (advancedFormId) => {
            // Create a copy of the form
            var tempElement = document.createElement('div');
            tempElement.innerHTML = angular.element(document.querySelector('#advancedForm_' + advancedFormId))[0].innerHTML;

            // Get the inputs and elements we want to hide for read only
            var formInputs = tempElement.getElementsByTagName('input');
            var formTextArea = tempElement.getElementsByTagName('textarea');
            var formSelect = tempElement.getElementsByTagName('select');
            var formSignature = tempElement.getElementsByTagName('signature');
            var readOnlyElements = tempElement.querySelectorAll('#readOnlyValue');
            var submitButtons = tempElement.getElementsByTagName('button');
            var labelElements = tempElement.getElementsByTagName(`label`);
            var transcludeElements = tempElement.getElementsByClassName('advanced-form-content');

            while (formInputs.length) {
                formInputs[0].remove();
            }
            while (formTextArea.length) {
                formTextArea[0].remove();
            }
            while (formSelect.length) {
                formSelect[0].remove();
            }
            readOnlyElements.forEach((element) => {
                element.hidden = false;
                element.style.visibility = '';
            });
            while (submitButtons.length) {
                submitButtons[0].remove();
            }
            while (formSignature.length) {
                formSignature[0].remove();
            }
            labelElements.forEach(label => {
                var labelElement = angular.element(label)[0];
                labelElement.outerHTML = "<label><b>" + labelElement.textContent + "</b></label>";
            })
            transcludeElements.forEach(element => {
                element.removeAttribute("ng-transclude");
            })

            // return read only html
            var formHtml = angular.element(tempElement)[0].innerHTML;
            return formHtml;
        }

        function getAdvancedFormDataFacets(formId) {

            return allevaApi.AdvancedForms.AdvancedFormElements.getElementsForFormId(formId)
                .then(advancedFormElements => {
                    if (!advancedFormElements){ 
                        // this form has no links to DataFacets
                        return;
                    }

                    const dataFacetIds = advancedFormElements.map(element => element.dataFacetId);
                    return allevaApi.AdvancedForms.DataFacets.search(dataFacetIds)
                    .then(dataFacets => {
                        return dataFacets;
                    })
                });
        }

        function getAdvancedFormData(formId = null) {

            const id = (formId !== null && formId !== undefined) ? formId : ctrl.advancedFormId;
            return getAdvancedFormDataFacets(id)
                .then((dataFacets) => {
                    if (!dataFacets)
                        return;

                    dataFacets.forEach(dataFacet => {
                        const element = document.querySelector('[data-facet-id="' + dataFacet.id + '"]');
                        if (element){
                            const isRequired = element.getAttribute('data-required') == 'true' ? true : false;
                            if (isRequired){
                                ctrl.requiredComponents.push(dataFacet.id);
                            }

                            if (dataFacet.dataKind == 'Signature'){
                                const isRequiredForComponents = element.getAttribute('data-required-by-components');
                                let components = JSON.parse(element.getAttribute('data-selected-signature-required-components'));                                
                                if (components && components.length > 0) {
                                    components = components.map(x => x.Id);
                                    ctrl.selectedSignatureRequiredComponents.push({ SignatureId: dataFacet.id, Components: components, Valid: true, IsRequriedForComponents: isRequiredForComponents });
                                }
                            }

                        }
                        
                    });

                    const data = {
                        leadId      : ctrl.leadId,
                        datafacetIds: dataFacets.map(facet => facet.id),
                        AdvancedFormInstanceId: ctrl.instanceId,
                        AdvancedFormId: (formId !== null && formId !== undefined) ? formId : ctrl.advancedFormId,
                    };

                   return allevaApi.AdvancedForms.DataValues.search(data)
                        .then((dataValues) => {
                            return {
                                dataValues: dataValues,
                                dataFacets: dataFacets
                            };
                        });
                });
        }

        /***************************
         * Saves
         **************************/
        ctrl.submit = function (saveForLater) {
            // TODO: validation
            $scope.missingRequired = false;
            ctrl.requiredComponents.forEach(componentId => {
                const dataFacet = ctrl.formDataFacets.find(df => df.id === componentId);
                if (dataFacet) {
                    let dataValue = ctrl.getComponentValueFromForm(dataFacet.uuid);
                    let isSignatureData = false;
                    let signatureHeight = 0;
                    let componentElement = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"]`);

                    if (dataFacet.dataKind == 'Signature') {
                        const input = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"] signature`);
                        dataValue = angular.element(input).jSignature('getData');
                        const blankData = angular.element(input).controller('signature').blankData;
                        isSignatureData = dataValue != blankData;
                        signatureHeight = parseInt(componentElement.getAttribute('data-height'));
                    }

                    if (dataFacet.dataKind == 'File'){
                        if (dataValue.Names == '') {
                            dataValue = null;
                        }
                    }

                    const missingRequiredSpan = componentElement.querySelector("#component-required");

                    if (!dataValue || (dataFacet.dataKind == 'DateTime' && dataValue == 'Invalid Date') || (dataFacet.dataKind == 'Signature' && !isSignatureData)) {
                        $scope.missingRequired = true;
                        if (!missingRequiredSpan) {
                            const newSpan = document.createElement("span");
                            newSpan.innerHTML = "This field is required";
                            newSpan.classList.add("red");
                            newSpan.classList.add("col-sm-12");
                            newSpan.id = "component-required";
                            componentElement.appendChild(newSpan);
                            componentElement.style.backgroundColor = '#f2dede';
                            componentElement.style.borderColor = '#ebccd1';
                            componentElement.style.padding = dataFacet.dataKind == 'Signature' ? '15px 15px ' + (signatureHeight + 100) + 'px 15px' : '15px 15px 30px 15px';
                            componentElement.style.border = '1px solid transparent';
                            componentElement.style.borderRadius = '4px';
                        }
                        
                    }
                    else if (dataValue && missingRequiredSpan) {
                        componentElement.removeChild(missingRequiredSpan);
                        componentElement.style.backgroundColor = '';
                        componentElement.style.borderColor = '';
                        componentElement.style.padding = '';
                        componentElement.style.border = '';
                        componentElement.style.borderRadius = '';
                    }
                }
            });

            // Show alert if required fields are incomplete and submitting or autosaving, not when saving for later
            if ($scope.missingRequired && ((!saveForLater && saveForLater != undefined) || ctrl.fromAutosave)) {
                let alert = "<h5>Required fields are incomplete</h5>";
                new noty({
                    text: alert,
                    timeout: 4000,
                    type: 'error'
                }).show();  
            }

            ctrl.selectedSignatureRequiredComponents.forEach(signatureComponent => {
                if (!signatureComponent.IsRequriedForComponents) {
                    $scope.$parent.formSubmitting = false;
                    return;
                }

                let hasData = false;

                // If signature is required by another signature
                const isIdInAnyComponentsList = ctrl.selectedSignatureRequiredComponents.some(item => {
                    return item.Components.includes(signatureComponent.SignatureId);
                });

                // Check if any component in the list has data
                signatureComponent.Components.forEach(componentId => {
                    const dataFacet = ctrl.formDataFacets.find(df => df.id === componentId);
                    if (dataFacet) {
                        let dataValue = ctrl.getComponentValueFromForm(dataFacet.uuid);
                        if (dataFacet.dataKind == 'Signature') {
                            const input = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"] signature`);
                            dataValue = angular.element(input).jSignature('getData');
                            let blankData = angular.element(input).controller('signature').blankData;
                            if (dataValue != blankData) {
                                hasData = true;
                            }
                        }
                        else if (dataValue) {
                            hasData = true;
                        }
                    }
                });

                // If there is data in the list, validate that signature is completed
                if (hasData || isIdInAnyComponentsList) {
                    const dataFacet = ctrl.formDataFacets.find(df => df.id === signatureComponent.SignatureId);
                    if (dataFacet) {
                        const input = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"] signature`);
                        let componentElement = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"]`);
                        let signatureHeight = parseInt(componentElement.getAttribute('data-height'));

                        const signatureRequiredSpan = componentElement.querySelector("#signature-required");
                        const dataValue = angular.element(input).jSignature('getData');
                        let blankData = angular.element(input).controller('signature').blankData;
                        if (dataValue != blankData) {
                            signatureComponent.Valid = true;
                            if (signatureRequiredSpan) {
                                componentElement.removeChild(signatureRequiredSpan);
                                componentElement.style.backgroundColor = '';
                                componentElement.style.borderColor = '';
                                componentElement.style.padding = '';
                                componentElement.style.border = '';
                                componentElement.style.borderRadius = '';
                            }
                        }
                        else {
                            if (!signatureRequiredSpan) {
                                signatureComponent.Valid = false;
                                const newSpan = document.createElement("span");
                                newSpan.innerHTML = "Signature is required";
                                newSpan.classList.add("red");
                                newSpan.id = "signature-required"
                                componentElement.appendChild(newSpan);
                                componentElement.style.backgroundColor = '#f2dede';
                                componentElement.style.borderColor = '#ebccd1';
                                componentElement.style.padding = dataFacet.dataKind == 'Signature' ? '15px 15px ' + (signatureHeight + 100) + 'px 15px' : '15px 15px 30px 15px';
                                componentElement.style.border = '1px solid transparent';
                                componentElement.style.borderRadius = '4px';
                            }
                        }
                    }
                }
            });

            // Check if all required signatures are completed
            const invalidSignatures = ctrl.selectedSignatureRequiredComponents.filter(value => value.Valid === false);
            if (invalidSignatures.length == 1) {
                let alert = "<h5>Signature is required.</p>";
                new noty({
                    text: alert,
                    timeout: 4000,
                    type: 'error'
                }).show();
                $scope.$parent.formSubmitting = false;                
                return;
            }
            else if (invalidSignatures.length > 1) {
                let alert = "<h5>Signatures are required.</p>";
                new noty({
                    text: alert,
                    timeout: 4000,
                    type: 'error'
                }).show();
                $scope.$parent.formSubmitting = false;
                return;
            }

            const formInstance = {
                advancedFormId: ctrl.advancedFormId,
                leadId: ctrl.leadId,
                sourceKey: ctrl.sourceKey,
                sourceTypeId: ctrl.sourceTypeId,
                id: ctrl.instanceId,
              };

            saveFormInstance(formInstance).then((response) => {
                ctrl.instanceId = response.id;
                const advancedFormData = {
                    AdvancedFormId: ctrl.advancedFormId,
                    LeadId: ctrl.leadId,
                    AdvancedFormInstanceId: response.id,
                };


                handleUploadAndSave(saveForLater, advancedFormData, response.id);
            });
        }
        
        let saveFormInstance = (formInstance) =>  allevaApi.AdvancedForms.FormInstance.add(formInstance).then((result) => Promise.resolve(result)).catch((error) => Promise.reject(error));
        
        async function handleUploadAndSave(saveForLater, advancedFormData, AdvancedFormInstanceId) {
            let uploadPromises = [];
            ctrl.formDataFacets.map(dataFacet => {
                if (dataFacet.dataKind == "File" && !ctrl.fromAutosave && !saveForLater && saveForLater != undefined){
                    let fileComponent = ctrl.$element[0].querySelector(`[data-guid="${dataFacet.uuid.toLowerCase()}"] fileupload`);
                    if (fileComponent){
                        const fileCtrl = angular.element(fileComponent).controller('fileupload');;
                        if (fileCtrl){
                            uploadPromises.push(fileCtrl.upload());
                        }  
                    }
                }
            });

            await Promise.all(uploadPromises);
            await saveData(saveForLater)
            .then((result) => {
                // save backup data
                if (!ctrl.isFormDataValid) {
                    const backupValues = [];
                    ctrl.badUuids.forEach((uuid) => {
                        const backupDataValue = ctrl.getComponentValueFromForm(uuid);
                        const item = {
                            AdvancedFormId: ctrl.advancedFormId,
                            AdvancedFormInstanceId: AdvancedFormInstanceId,
                            CreatedDate: new Date(),
                            LeadId: ctrl.leadId,
                            Uuid: uuid,
                            Value: backupDataValue,
                        } ;
                        backupValues.push(item);
                    });
                    allevaApi.AdvancedForms.DataBackup.add(backupValues);
                }
                advancedFormData.AdvancedFormHtml = getReadOnlyHtml(ctrl.advancedFormId),
                allevaApi.AdvancedForms.saveAdvancedFormHtnl(advancedFormData);
                
                ctrl.saved = true;

                if(!ctrl.isFormDataValid){
                    let alert = "<h5>Some form components failed to save. Please contact a facility representative.</p>";
                    new noty({
                        text: alert,
                        timeout: 3000,
                        type: 'warning'
                    }).show();

                    if(!saveForLater && saveForLater != undefined){
                        $scope.$parent.formSubmitting = false;
                        return;
                    }
                }

                else if(!ctrl.fromAutosave){
                    let alert = "<h5>Form data saved successfully.</p>";
                    new noty({
                        text: alert,
                        timeout: 4000,
                        type: 'success'
                    }).show();
                }

                if(!ctrl.fromAutosave){
                    ctrl.formObject.IsCompleted = isCompleted();
                }

                // Prevent completing and exiting form if required fields are incomplete and not saving for later
                if ($scope.missingRequired && ((!saveForLater && saveForLater != undefined) && !ctrl.fromAutosave) ) { 
                    $scope.$parent.formSubmitting = false;
                    return;
                }
                
                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved, ctrl.fromAutosave);

                ctrl.fromAutosave = false;
            })
            .catch((error) => {
                let alert = "<h5>Error saving form data.</p>";
                    new noty({
                        text: alert,
                        timeout: 8000,
                        type: 'error'
                    }).show();

                ctrl.saved = false;
                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved, error);
                });
        }

        const saveData = (saveForLater) => {
            const data = ctrl.formDataFacets.map(dataFacet => {

                if (dataFacet.dataKind == DataKind.File && (saveForLater || saveForLater == undefined)){
                    return null;
                }
                let value = ctrl.getComponentValueFromForm(dataFacet.uuid);

                // TODO: handle null dataValue
                const item  = {
                    Id            : value ? value.id : undefined,
                    AdvancedFormInstanceId: ctrl.instanceId,
                    DataFacetId   : dataFacet.id,
                    IntegerValue  : dataFacet.dataKind == DataKind.Integer ? value  : null,
                    StringValue   : dataFacet.dataKind == DataKind.Text ? value     : null,
                    DateTimeValue : dataFacet.dataKind == DataKind.DateTime ? value : null,
                    DecimalValue  : dataFacet.dataKind == DataKind.Decimal ? value  : null,
                    ImageValue    : dataFacet.dataKind == DataKind.Image ? value    : null,
                    FileValue     : dataFacet.dataKind == DataKind.File ? value.Ids : null,
                    SignatureValue: dataFacet.dataKind == DataKind.Signature ? value: null,
                    BooleanValue  : dataFacet.dataKind == DataKind.Boolean ? value  : null,
                    JsonValue     : dataFacet.dataKind == DataKind.Json ? value    : null,
                    leadId        : ctrl.leadId
                };

                const inputs = getInputs(dataFacet.uuid);
                var readOnlyElements = getReadOnlyElements(dataFacet.uuid);
                var defaultReadOnlyElement = readOnlyElements[0];
                
                inputs.forEach(input => {
                    switch (input.type) {
                        case 'select-one':
                            if (defaultReadOnlyElement) {
                                input.forEach(option => {
                                    if (option.selected) {
                                        angular.element(defaultReadOnlyElement)[0].textContent = option.label;
                                    }
                                });
                            }
                            break;
                        case 'time':
                            angular.element(defaultReadOnlyElement)[0].textContent = dayjs.utc(value, 'h.mm.a').local().format('h:mm a');
                            break;
                        case 'checkbox':
                            if (value) {
                                const splitValues = value.split(",");
                                angular.element(input).attr('checked', splitValues.includes(input.value));
                            }
                            readOnlyElements[input.id].src = input.checked ? 'Images/faChecked.png' : 'Images/faCross.png';
                            break;
                        case 'radio':
                            angular.element(input).attr('checked', (input.value == value));
                            readOnlyElements[input.id].src = input.checked ? 'Images/faChecked.png' : 'Images/faCross.png';
                            break;
                        case 'textarea':
                            angular.element(defaultReadOnlyElement)[0].outerHTML = "<p id=readOnlyValue hidden=\"\">" + value.replace(/\n/g, "<br>") + "</p>";
                            break;
                        case 'multiselect':
                            const multiselectCtrl = angular.element(input).controller('advancedformmultiselect');
                            if (multiselectCtrl) {
                                if (defaultReadOnlyElement) {
                                angular.element(defaultReadOnlyElement)[0].innerHTML = value.split(',').join('<br>');
                                }
                            }
                            break;
                        case 'file':
                            if (defaultReadOnlyElement) {
                                angular.element(defaultReadOnlyElement)[0].textContent = value.Names;
                            }
                        default:
                            if (defaultReadOnlyElement && input.tagName == 'SIGNATURE') {
                                value = angular.element(input).jSignature('getData');
                                item.SignatureValue = value;
                                angular.element(input.querySelector('.signature-canvas-target')).jSignature('setData', value);
                                angular.element(defaultReadOnlyElement).attr('src', value);
                            }
                            else if (defaultReadOnlyElement && input.name == "timeInput") {
                                const formattedTime = value ? dayjs.utc(value, 'h:mm A').local().format('h:mm A') : '';
                              angular.element(defaultReadOnlyElement)[0].textContent = formattedTime;
                            }
                            else if (defaultReadOnlyElement && input.tagName == 'FILEUPLOAD'){
                                angular.element(defaultReadOnlyElement)[0].textContent = value.Names;
                            }
                            else if (defaultReadOnlyElement && input.tagName == 'CLIENTMEDICATIONS') {
                                angular.element(defaultReadOnlyElement)[0].innerHTML = value;
                            }
                            else if (defaultReadOnlyElement && input.tagName == 'ADVANCEDFORMMULTISELECT') {
                                const multiselectCtrl = angular.element(input).controller('advancedformmultiselect');
                                if (multiselectCtrl) {
                                  if (defaultReadOnlyElement) {
                                    angular.element(defaultReadOnlyElement)[0].innerHTML = value.split(',').join('<br>');
                                  }
                                }
                            }
                            else if (defaultReadOnlyElement) {
                                angular.element(defaultReadOnlyElement)[0].textContent = value;
                            }
                            break;
                    }
                });
                return item;
            }).filter(item => item !== null);

            return allevaApi.AdvancedForms.submitForm(ctrl.advancedFormId, ctrl.leadId, data);
        }
        
        /***************************
         * Helpers
         **************************/
        const getValueForKind = (data, kind) => {
            switch (kind) {
                case DataKind.Integer: 
                    return data.integerValue;
                case DataKind.Boolean: 
                    return data.booleanValue;
                case DataKind.Text: 
                    return data.stringValue;
                case DataKind.Decimal: 
                    return data.decimalValue;
                case DataKind.Image: 
                    return data.imageValue;
                case DataKind.File: 
                    return data.fileValue;
                case DataKind.DateTime: 
                    return data.dateTimeValue;
                case DataKind.Signature: 
                    return data.signatureValue;
                case DataKind.Json:
                    return data.jsonValue;
            }
        }

        function isCompleted() {
            // TODO: iterate over required values
            return true;
        }

        function startAutosaveTimer () {
            $interval.cancel(ctrl.autosaveTimer); // reset timer
            ctrl.countDownTime = ctrl._autoSaveTime;    
            ctrl.autosaveTimer = $interval( () => {
                ctrl.countDownTime--;
                if(ctrl.countDownTime === 0){
                    if(ctrl.isDirty){
                        ctrl.fromAutosave = true;
                        ctrl.submit();
                        ctrl.isDirty = false;
                    }
                    $interval.cancel(ctrl.autosaveTimer);
                }
            },1000,ctrl._autoSaveTime);
        };

        function validateFormData() {
            var parentDiv = angular.element(document.querySelector(`[advanced-form-id="${ctrl.advancedFormId}"]`));
  
            // Find all child divs inside the parent with 'data-facet-id' attribute
            var childDivsWithFacetId = Array.from(angular.element(parentDiv).find('div[data-guid]'))
                .filter(div => !div.classList.contains('column-section'));  
  
            // Initialize an array to hold the values of 'data-facet-id'
            ctrl.badUuids = [];
  
            // Check if each facetid is present in the facetsSaving array
            angular.forEach(childDivsWithFacetId, function (value, key) {
                var id = angular.element(value).attr('data-guid').toLowerCase();
                var facet = ctrl.formDataFacets.find(x => x.uuid.toLowerCase() == id);
                if (!facet) {
                    ctrl.badUuids.push(id);
                    var missingRequiredSpan = value.querySelector('#component-mismatch');
                      if (!missingRequiredSpan) {
                          var newSpan = document.createElement('span');
                          newSpan.innerHTML = 'Data for this component is unable to be saved. Please contact a facility representative.';
                          newSpan.classList.add('red');
                          newSpan.classList.add('col-sm-12');
                          newSpan.id = 'component-mismatch';
                          value.appendChild(newSpan);
                          value.style.backgroundColor = '#f2dede';
                          value.style.borderColor = '#ebccd1';
                          value.style.padding = '15px 15px 30px 15px';
                          value.style.border = '1px solid transparent';
                          value.style.borderRadius = '4px';
                      }
                      
                  }
            });
  
            if (ctrl.badUuids.length > 0) {
                ctrl.isFormDataValid = false;
                return false;
            }
            else {
                return true;
            }
        }
    };

